import React from 'react';
import '../../components/Header.css'
import './Gallery.css'
import Card from './components/Card';
import SecondaryHeader from '../../components/SecondaryHeader';
import GeneralLibraryImage from './components/GeneralLibraryImage';
import SectionTitle from '../../components/SectionTitle';

import before1 from '../../assets/beforeAndAfter/before1.jpg'
import before2 from '../../assets/beforeAndAfter/before2.jpg'
import before3 from '../../assets/beforeAndAfter/before3.jpg'
import before4 from '../../assets/beforeAndAfter/before4.jpg'
import before5 from '../../assets/beforeAndAfter/before5.jpg'
import before6 from '../../assets/beforeAndAfter/before6.jpg'
import before7 from '../../assets/beforeAndAfter/before7.jpg'
import before8 from '../../assets/beforeAndAfter/before8.jpg'

import after1 from '../../assets/beforeAndAfter/after1.jpg'
import after2 from '../../assets/beforeAndAfter/after2.jpg'
import after3 from '../../assets/beforeAndAfter/after3.jpg'
import after4 from '../../assets/beforeAndAfter/after4.jpg'
import after5 from '../../assets/beforeAndAfter/after5.jpg'
import after6 from '../../assets/beforeAndAfter/after6.jpg'
import after7 from '../../assets/beforeAndAfter/after7.jpg'
import after8 from '../../assets/beforeAndAfter/after8.jpg'


import generalGalleryImages from './components/idk';



function Gallery() {

    
    
    

    const [galleryToMount, setGalleryToMount] = React.useState('beforeAndAfter')

    let generalGalleryImageComponents = []
    
    // REALLY BAD PRACTICE USING INDEX AS IMAGE LALALAL BUT AM BASED HAHAH
    for(let i = 0; i < generalGalleryImages.length; i++){
        generalGalleryImageComponents.push(<GeneralLibraryImage key={i} image={generalGalleryImages[generalGalleryImages.length - 1 - i]} />)
    }
    
    return (
    <div className="Gallery" >
        <SecondaryHeader headerTitle='GALLERY' />
        <div className="gallery-background" ></div>
        <div className='gallery-wrapper'>
            <div className='gallery-intro'>
                <h2 className='sectionButton' onClick={() =>{setGalleryToMount('beforeAndAfter')}} style={{borderRight: '1px solid white'}}>Before & Afters</h2> 
                <h2 className='sectionButton' onClick={() =>{setGalleryToMount('generalGallery')}}style={{borderLeft: '1px solid white'}}>General Gallery</h2>
            </div>
            {galleryToMount == 'beforeAndAfter' && 
            <>
            <SectionTitle title='Before & Afters' />
            <div className='before-and-after-gallery-grid-container'>

                <Card frontImage={before8} 
                backImage={after8}/>

                <Card frontImage={before4} 
                backImage={after4}/>

                <Card frontImage={before6} 
                backImage={after6}/>

                <Card frontImage={before1} 
                backImage={after1}/>
                
                <Card frontImage={before5} 
                backImage={after5}/>
                
                <Card frontImage={before2} 
                backImage={after2}/>
                
                <Card frontImage={before3} 
                backImage={after3}/>

                <Card frontImage={before7} 
                backImage={after7}/>


                
                
                
            </div>
            </>
            }
            {galleryToMount == 'generalGallery' && 
            <>
            <SectionTitle title='General Gallery' />
            <div className='general-gallery-grid-container'>
                {generalGalleryImageComponents}
            </div>
            </>
            }
        </div>
    </div>
  );
}

export default Gallery;
