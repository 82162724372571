import './SectionTitle.css'


// component expects a 'text' property - giving it a the text in the black box
// also expects a background property - which can either be a color (given by a css string - e.g. "(rgba('255, 0, 0, 1')
// or an image url (witht he css prop synthax again... - 'url(...chosen image...)')
function SectionTitle(props) {

  return (
    <div className="SectionTitle" >
      <hr ></hr>
      <hr ></hr>
      <h2 >{props.title}</h2>
      <hr ></hr>
      <hr ></hr>
    </div>
  );
}

export default SectionTitle;








