import React from 'react';

import flowerListStyle from '../../assets/flowerListStyle.svg'


// component expects a 'text' property - giving it a the text in the black box
// also expects a background property - which can either be a color (given by a css string - e.g. "(rgba('255, 0, 0, 1')
// or an image url (witht he css prop synthax again... - 'url(...chosen image...)')
function AboutUsSection(props) {

  

 
  return (
    <section className="AboutUsSection" style={{  background: !props.image ? `url(${props.background})` : props.background}}>
      
      <div className='customHrDiv' />
      <div className='about-us-outer-wrapper' >
        {/* <div className='about-us-outer-wrapper' ref={contentRef} style={{backgroundColor: 'rgba(0,0,0,0.9)', margin: '0 auto', padding: '1rem', borderRadius: '1rem', transition: 'all 1.5s', opacity: `${props.appear ? 1 : 0}`, transform: `translate(${props.appear ? 0 : -20}%, 0)`}}> */}
          {props.title ? (<><h1 style={{margin: '0', color: 'rgb(210,210,210)', textAlign: 'center'}}>{props.title}</h1> <hr/></>) : <></>}
        {/* <h3 style={{textAlign: 'center', fontSize: 'clamp(1rem, 2vw, 1.5rem)', color: 'rgb(210,210,210)', lineHeight: '150%'}}>{props.text}</h3> */}
        <ul style={{listStyle:`url(${flowerListStyle})`}}>
          {props.list1 && <li>{props.list1}</li>}
          {props.list2 && <li>{props.list2}</li>}
          {props.list3 && <li>{props.list3}</li>}
          {props.list4 && <li>{props.list4}</li>}
          {props.list5 && <li>{props.list5}</li>}
        </ul>
        </div>
        <div className='customHrDiv' />
      
    </section>
  );
}

export default AboutUsSection;
