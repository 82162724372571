import React from "react";
import '../../specHome.css'
import { Link } from 'react-router-dom';

import useScrollToTop from "../../../hooks/scrollToTop";


function HomeIntroCard(props) {

  return (
    <div className='lolz' style={{ background: `url('${props.backgroundImage}')`  }}>
        <div className='intro-cards-paragraph-wrapper'>
            <p>{props.text}</p>
            
            <Link to={props.linkTo} >
              <button className='lolBtn' onClick={useScrollToTop}>{props.buttonText}</button>
            </Link>
        </div>          
    </div>
  );
}

export default HomeIntroCard;
