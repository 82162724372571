import React from "react";
import './Header.css'
import sun3 from '../assets/sun3.png'

import img1 from '../assets/roseBush.png'




function Header() {

  

  let maskRef = React.useRef()
  let leftBranchRef = React.useRef()
  let rightBranchRef = React.useRef()
  let titleh1Ref = React.useRef()

  let purpleTullipsRef = React.useRef();
  let yellowTullipsRef = React.useRef();
  React.useEffect(()=>{

    function handleScrollParallax() {
      let windowScrollY = window.scrollY;

      yellowTullipsRef.current.style.width = `calc(${windowScrollY * 0.03}% + 150%)`
      purpleTullipsRef.current.style.width = `calc(-${windowScrollY * 0.02}% + 150%)`

      maskRef.current.style.height= `calc(${windowScrollY * 0.08}px + 30%)`;
      sunRef.current.style.transform = `translate(calc( ${0}px + 40%), calc(${windowScrollY * 0.5}px - 80%))`
      // titleDecorationMaskRef.current.style.inset = `calc(${windowScrollY * 0.2}px + 18rem) 0`
      titleRef.current.style.transform = `translateY(calc(-${windowScrollY * 0.3}px))`

      //titleh1Ref.current.style.textShadow =  `-1px -1px ${windowScrollY * 0.005}rem black, 1px -1px ${windowScrollY * 0.005}rem black, -1px 1px ${windowScrollY * 0.005}rem black, 1px 1px ${windowScrollY * 0.005}rem black`
      titleh1Ref.current.style.filter = `blur(${windowScrollY * 0.01}rem)`;
      headerRef.current.style.backgroundPosition = `-${windowScrollY * 0.01}rem calc(-${windowScrollY * 0.005}rem - 1vh) `
      
      leftBranchRef.current.style.left = `calc(${windowScrollY * 0.07}% + 10%)`
      leftBranchRef.current.style.top = `calc(-${windowScrollY * 0.04}% + 2rem) `

      rightBranchRef.current.style.right = `calc(${windowScrollY * 0.07}% + 10%)`
      rightBranchRef.current.style.top = `calc(-${windowScrollY * 0.04}% + 2rem) `
    }
    window.addEventListener('scroll', handleScrollParallax)

    return () =>{ 
      window.removeEventListener('scroll', handleScrollParallax)
    }
  },[])

  
  // React.useEffect(()=>{
  //   let scrollPosition = window.pageYOffset
  //   function handleScroll(){
  //     if(scrollPosition < window.pageYOffset && scrollPosition > 150){
  //       navRef.current.style.transform = 'translate(0, -200px)'
  //     }else{
  //       navRef.current.style.transform = 'translate(0, 0px)'
  //     }
      
  //     setTimeout(() =>{
  //       scrollPosition = window.pageYOffset
  //     }, 50)
    
    
      
  //   }
  //   window.addEventListener('scroll', handleScroll)

  //   return () => {
  //     window.removeEventListener('scroll', handleScroll)
  //   }
  // },[])
 
    
    let sunRef = React.useRef()
   
    let titleRef= React.useRef()
    let headerRef = React.useRef()
  return (
    <>
    
    <header className="Header" >
    
        {/* <nav ref={navRef} className="header-nav">
          <div className="contactAndSocialMediaNav">
            <div className="socials">
              <h3 className="contactTitleAndLink">Contact Us</h3>
              <h3 className="contactTitleAndLink">Contact Us</h3>
            </div>
            <h3 className="contactTitleAndLink1">Contact Us</h3>
          </div>
          <div className="separation-line"></div>
          <div className="main-nav">
          <ul className="idk">
          
          <Link style={{textDecoration: 'none'}} to='/'><li className="home-btn">Home</li></Link>
                    
                
                    <div></div>
                    <div></div>
                    <div></div>
                    <Link style={{textDecoration: 'none'}} to='/services'><li>Services</li></Link>
                    
                    <Link style={{textDecoration: 'none'}} to='/gallery'><li>Gallery</li></Link>

                    <Link style={{textDecoration: 'none'}} to='/about'><li>About</li></Link>
              
            </ul>
          </div>
        </nav> */}
        <div ref={leftBranchRef} id='img6' ></div>
        <div ref={rightBranchRef} id='img10' ></div>
        <div ref={maskRef} className='flowers-container'>
        <img id='img1' src={img1}></img>
        <div id='img2' ></div>
        <div ref={purpleTullipsRef} id='img3' ></div>
        <div id='img4' ></div>
        

        
        <div id='img7' ></div>
        <div id='img8' ></div>
        <div ref={yellowTullipsRef}  id='img9' ></div>
        </div>
        {/* <img id='img3' src={img1}></img>
        <img id='img4' src={img1}></img> */}
        <div className="top-gap"></div>
        {/* {<img className='lulimg'src={fku} ></img>} */}
        <div  className="container-mask">
        <div ref={headerRef} id='img5' ></div>
          <div ref={titleRef}className="containerhh" >
            <h1 ref={titleh1Ref} className="h1-outside-mask">Valentine's Gardening</h1>
            <div style={{maskRepeat: 'no-repeat'}} className="image-mask2"></div>
          </div>
          
          <img className="sun-img" alt='Image of a sun, animated to rise upwards'ref={sunRef} src={sun3} />
        </div>
        {/* <div ref={maskRef} className="image-mask"> */}
        
        {/* {<h1 className="h1-in-mask">Valentines Gardens</h1> }  */}
        
        {/* </div> */}
        
        
        
    </header>
    
    </>
    
  );
}

export default Header;
