import React from 'react';
import Header from '../components/Header';
import clock1 from '../assets/clock1.png'
import './specHome.css'
import HomeIntroCard from './Home/components/HomeIntroCard';

import PageBackground from '../components/PageBackground';

import crosshairImage from '../assets/crosshair.png'
import bellImage from '../assets/bell.png'
import Reviews from './Home/components/Reviews';
import SectionSplitter from '../components/SectionSplitter';

import homeProject1 from '../assets/homeProjects1.jpg'
import homeProject2 from '../assets/homeProjects2.jpg'
import homeProject3 from '../assets/homeProjects3.jpg'

import aboutUsCardImage from '../assets/aboutUsCardImage.jpg'
import servicesCardImage from '../assets/servicesCardImage.jpg'
import contactCardImage from '../assets/contactCardImage.jpg'

import useScrollToTop from '../hooks/scrollToTop';
import { Link } from 'react-router-dom';

import roseBasket from '../assets/roseBasket.jpg'

import hammer from '../assets/hammer.png'
import mowing from '../assets/mowing.jpg'

import AutumnHeader from '../components/SeasonalHeaders/AutumnHeader/AutumnHeader';



function Home(props) {
    //background: 'linear-gradient(rgba(29, 80, 14, 1)  1%, hsla(10, 79%, 26%, 1) ) '
    // 'linear-gradient(-45deg, hsl(73, 64%, 35%) 25%, hsl(113, 60%, 21%)'
   
    //45deg, red 0 50%, blue 50% 100%


    React.useEffect(()=>{
        const boxElementList = document.querySelectorAll('.lolz2')

        const observer = new IntersectionObserver((entries)=>{
          entries.forEach((entry) =>{
            if(entry.isIntersecting){
              entry.target.classList.add('elementAppear')
            }
            // else {
            //   entry.target.classList.remove('elementAppear')
            // }
          })
        })

        boxElementList.forEach((element) => observer.observe(element))

        const galleryPreviewSections = document.querySelectorAll('.picture-and-description-inner-grid-container')

        galleryPreviewSections.forEach((element) => observer.observe(element))
    },[])

  return (
    <div className="Home" >
      <Header/>
      {/* <AutumnHeader viewportWidth={props.viewportWidth}/> */}
      <SectionSplitter text='Simply Relax And Let Me Do The Hard Work'/>
     
        <div className='home-section-outermost-container' >
        
            
            
            
              <div className='home-title-container'>
                <h2 >Professional</h2>
                <h1 >Gardening & Landscaping Services</h1>
                <h3 >Based In London</h3>
              </div>
            
            <hr className='longest-hr'></hr>
            <hr className='mid-length-hr-title'></hr>

            <div className='intro-cards-grid-container'>
                
                <HomeIntroCard 
                    backgroundImage={servicesCardImage} 
                    text="Our services offer a fully customized experience, alongside our professional advice. With a huge range of services on affordable prices, in order to meet all your gardening needs at once."
                    buttonText="Discover Our Services"
                    linkTo='/services' 

                />

                <HomeIntroCard 
                    backgroundImage={aboutUsCardImage}  
                    text="We are a professional team with a passion for gardening and more than 10 years of experience, guaranteeing top quality installations and matinenance."
                    buttonText="Learn More About Us"
                    linkTo='/about' 
                />
                <HomeIntroCard 
                    backgroundImage={contactCardImage} 
                    text={`Whether you have a question regarding about our services, want to place an order, or need advice, we are dedicated to help you. Reach out today and let us know how can we assist you!`}
                    buttonText="Contact Us"
                    linkTo='/contact'
                />
                
            </div>
            <div  className='home-section-sub-title-container' >
              <h2 >Why Should You Consider Our Services</h2>
            </div>
            
            <hr className='mid-length-hr'></hr>
            <hr className='shortest-hr'></hr>
            

            <div className='why-choose-us-grid-container'>
                <div className='lolz2' ><img className='lolz2-image-with-filter' src={clock1} /><p>We are flexible and finish the job in a timely manner</p></div>
                <div className='lolz2' ><img className='lolz2-image-with-filter' src={crosshairImage}/><p>Get personalized services tailored to your preferences</p></div>
                <div className='lolz2' ><img className='lolz2-image-no-filter' src={hammer} /><p>A single provider for all your handiwork and gardening needs</p></div>
                <div className='lolz2' ><img className='lolz2-image-with-filter' src={bellImage} /><p>Receive professional advice alongside with our work</p></div>
            </div>
        
            
            <PageBackground image={roseBasket} opacity='40%' bgSize='cover'/>
            {/* <div className='pisofshit' style={{  filter:' blur(3px) opacity(30%)', background: 'url("https://cdn.pixabay.com/photo/2016/08/03/14/24/roses-1566792_960_720.jpg")', backgroundSize:'cover', position: 'absolute',  zIndex: '-1'}}></div> */}
            {/*HERE */}
        </div>
        {/* <div style={{height: '5rem', width: '100',  background: 'black'}}></div> */}
        
        <SectionSplitter text='Call us today: 07460 717 222'/>
        <div className='home-section-outermost-container' >

        <div className='home-section-sub-title-container' >
            <h2  >Have a Glance at Some of Our Projects</h2>
          </div>

            <hr className='mid-length-hr'></hr>
            <hr className='shortest-hr'></hr>


            <div className='intro-gallery-grid-container' >

            <div className='picture-and-description-inner-grid-container'>
            <div className='intro-gallery-grid-item' ><img className='intro-gallery-photo' src={homeProject1}></img></div>
               <h3>Hedge shaping - precise cutting, trimming and pruning</h3>
            </div>
              
              <div className='picture-and-description-inner-grid-container'>
              { window.innerWidth > 700 && <h3>Long term regular garden maintenance</h3>}
               <div className='intro-gallery-grid-item' ><img className='intro-gallery-photo' src={homeProject2}></img></div>
               { window.innerWidth < 700 && <h3>Long term regular garden maintenance</h3>}
              </div>
               
               
               <div className='picture-and-description-inner-grid-container'>
               <div className='intro-gallery-grid-item' ><img className='intro-gallery-photo' src={homeProject3}></img> </div>
               <h3>Full garden design</h3>
               </div>
               {/* <div className='intro-gallery-grid-item' ><img className='intro-gallery-photo' src={photo}></img><p >Photo Name 2</p> </div> */}
               {/* <div style={{background: 'rgba(0,0,0,0.9)', borderRadius: '0.5rem 0.5rem 2px 2px', padding: '1px', overflow: 'hidden', boxShadow: '0 0 1rem 0.5rem black'}}><p style={{color: 'white', textAlign: 'center'}}>Photo Name 2</p><img style={{margin: '0',width: '100%', borderRadius: '0rem 0rem 2px 2px'}}src={photo}></img> </div>
               <div style={{background: 'rgba(0,0,0,0.9)', borderRadius: '0.5rem 0.5rem 2px 2px', padding: '1px', overflow: 'hidden', boxShadow: '0 0 1rem 0.5rem black'}}><p style={{color: 'white', textAlign: 'center'}}>Photo Name 2</p><img style={{margin: '0',width: '100%', borderRadius: '0rem 0rem 2px 2px'}}src={photo}></img> </div> */}
            </div>


            <Link to='/gallery'><button onClick={useScrollToTop} className='see-more-gallery-redirect-btn'>Press to See More</button></Link>

            <PageBackground image={mowing} opacity='30%' bgSize='cover'/>
        </div>
        
        <SectionSplitter text='e-mail: vboradzhiev@gmail.com' />
        <div style={{background: 'rgba(200,200,200, 0.2)', margin: '0', padding: '2rem 0'}}>

        <div className='home-section-sub-title-container' >
            <h2 >Client Reviews</h2>
            </div>

            <hr className='mid-length-hr'></hr>
            <hr className='shortest-hr'></hr>
            <Reviews />
            
        </div>
        
               <div className='see-more-div'>
               <h3 >Want to see more?</h3> 
              <h3 >Check out our <a target='_blank' href='https://www.yell.com/biz/valentine-s-garden-services-london-8934613/'>YELL</a> and <a target='_blank' href='https://www.bark.com/en/gb/company/boradzhievi-ltd/QO1a/'>BARK</a> pages!</h3>
      
               </div>

        {/* <HomePageSection appear={scrollPosition > 200 ? true : false} background={garden1} text={'At Valentine’s you can find the right service, tailored to your garden needs. We aim to deliver quality services, offering a variety of gardening help and labor. Starting from basic garden consultations, trough garden installation and maintenance, to remodeling and restoring of your outdoor spaces, we provide a list of specialized services for every element in your garden'} />
      <h1 style={{fontSize: '3.75rem',margin: '0', textAlign: 'center', color: 'white', letterSpacing: '0.1rem', wordSpacing: '0.5rem 0',  fontFamily: "'Staatliches', cursive", backgroundColor: 'rgba(0,0,0,0.9)'}}>Call me today: 07460 717 222</h1>
      <HomePageSection appear={scrollPosition > 700 ? true : false} image={true} background='linear-gradient(90deg, rgba(200, 200, 200, 0.5), rgba(51, 51, 51, 1))' text={'At Valentine’s you can find the right service, tailored to your garden needs. We aim to deliver quality services, offering a variety of gardening help and labor. Starting from basic garden consultations, trough garden installation and maintenance, to remodeling and restoring of your outdoor spaces, we provide a list of specialized services for every element in your garden'} />
      <h1 style={{fontSize: '3.75rem',margin: '0', textAlign: 'center', color: 'white', letterSpacing: '0.1rem', wordSpacing: '0.5rem 0',  fontFamily: "'Staatliches', cursive", backgroundColor: 'rgba(0,0,0,0.9)'}}>e-mail: vboradzhiev@gmail.com</h1>
      <HomePageSection  appear={scrollPosition > 1380 ? true : false} background={garden2} text={'At Valentine’s you can find the right service, tailored to your garden needs. We aim to deliver quality services, offering a variety of gardening help and labor. Starting from basic garden consultations, trough garden installation and maintenance, to remodeling and restoring of your outdoor spaces, we provide a list of specialized services for every element in your garden'} /> */}
      




    </div>
  );
}

export default Home;
