


// component expects a 'text' property - giving it a the text in the black box
// also expects a background property - which can either be a color (given by a css string - e.g. "(rgba('255, 0, 0, 1')
// or an image url (witht he css prop synthax again... - 'url(...chosen image...)')
function PageContentWrapper(props) {

  return (
    <div className="PageContentWrapper" style={{padding: '0 5vw 3rem 5vw', margin: '0 auto', background: 'rgba(255, 255, 255, 0.65)', width: '80%'}}>
      {props.children}
    </div>
  );
}

export default PageContentWrapper;








