
import { useEffect, useState, useRef } from "react";
import './Header.css'
import { NavLink } from "react-router-dom";
import './Nav.css'
import smIcon from '../assets/social-media-icons.png'
import facebookIcon from '../assets/facebook-icon.svg'
import instagramIcon from '../assets/instagram-icon.svg'
import barkLogo from '../assets/barkLogo.png'
import yellLogo from '../assets/yellLogo.png'
import phoneIcon from '../assets/phone-icon.svg'
import mailIcon from '../assets/mail-icon.svg'
import useScrollToTop from "../hooks/scrollToTop";

function Nav() {

    const [navPageSelect, setNavPageSelect] = useState('HOME')
    const [activateBurgerNav, setActivateBurgerNav] = useState(false)
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth)
    const [scrollingDown, setScrollingDown] = useState(false)
    const [scrollPosition, setScrollPosition] = useState(window.scrollY)


    
    function burgerClickHandler(){
        setActivateBurgerNav(prevState => !prevState)
    }

    function burgerMenuConditionsAreMet(){
        return (activateBurgerNav && viewportWidth < 700 ? true : false)
    }

    

    
    
    useEffect(()=>{
      // let burgerBtn = document.getElementsByClassName('burger-btn')
      // if(document.getElementsByClassName('burger-btn-active') != null){
      //   let activeBurger = document.getElementsByClassName('burger-btn-active')
      // activeBurger[0].addEventListener('click',()=>{
      //   setTimeout(()=>{
      //     burgerNavBtn.current.style.display = 'none'
      //   }, 1000)
      // })
      // }
      
    setTimeout(() => {
      if(!activateBurgerNav && document.getElementsByClassName('main-nav-active')[0] == undefined && viewportWidth < 700){
        burgerNavBtn.current.style.display = 'none'
    }
    }, 1000);

    setTimeout(()=>{
       if(activateBurgerNav && document.getElementsByClassName('main-nav-active')[0] != undefined && viewportWidth < 700){
        burgerNavBtn.current.style.display = 'unset'
      }
    }, 0)

        const handleResize = () => {
            setViewportWidth(window.innerWidth)
        }
        
        window.addEventListener('resize', handleResize)
        

        // let scrollPosition = window.scrollY
        function handleScroll(){

            if(activateBurgerNav === false){
              
                if(scrollPosition > window.scrollY  ){
                    
                    setScrollingDown(false)
                  }else if(scrollPosition < window.scrollY && scrollPosition > 150) {
                    setScrollingDown(true)
                  }
                  
                  setTimeout(() =>{
                setScrollPosition(window.scrollY)
              }, 100)
            }
          
          
        }
        window.addEventListener('scroll', handleScroll)
    
        return () => {
          window.removeEventListener('scroll', handleScroll);
          window.removeEventListener('resize', handleResize)
        }
      },[activateBurgerNav, viewportWidth, scrollPosition, window.scrollY])
     let burgerNavBtn = useRef() 
        

  return (
    <nav  className={scrollingDown ? 'header-nav scrollingDown' : 'header-nav'}>
    <div className="contactAndSocialMediaNav">
      <div className="socials">
        <a target='_blank' href='https://www.facebook.com/valentinesgardening' rel="noreferrer"><img className='social-media-icon facebook-icon' src={facebookIcon} /></a>
        <a target='_blank' href='https://instagram.com/valentin.gardening?igshid=ZDdkNTZiNTM=' rel="noreferrer"><img className='social-media-icon instagram-icon' src={instagramIcon} /></a>
        <a target='_blank' href='https://www.yell.com/biz/valentine-s-garden-services-london-8934613/' rel="noreferrer"><img className='social-media-icon instagram-icon' src={yellLogo} /></a>
        <a target='_blank' href='https://www.bark.com/en/gb/company/boradzhievi-ltd/QO1a/' rel="noreferrer"><img className='social-media-icon instagram-icon' src={barkLogo} /></a>
      </div>
      <div className='contact-info-grid-container'>
        <div className='contact-info-wrapper info-wrapper1'>
            <img className='phone-icon' src={phoneIcon}/><h4 className="contact-info info1">07460 717222</h4>
        </div>
        <div className='contact-info-wrapper info-wrapper2'>
            <img className='mail-icon' src={mailIcon}/> <h4 className="contact-info info2">vboradzhiev@gmail.com</h4>
        </div>
      </div>
    </div>
    <div className="separation-line"></div>
    <div className={ burgerMenuConditionsAreMet() ? "main-nav main-nav-active" : 'main-nav'}>

    { viewportWidth > 700 ? <ul className="idk">

    <NavLink onClick={()=>{setNavPageSelect('HOME')}} to='/'><h2 className='navTitle'>Valentine's Gardening</h2></NavLink>
                    <div></div>
                    
        <NavLink onClick={()=>{setNavPageSelect('HOME')}} to='/'><li className={navPageSelect == 'HOME' ? 'home-btn selectedAsCurrentPage': 'home-btn'} onClick={useScrollToTop}>Home</li></NavLink>
                    
                
                    {/* <div></div>
                    <div></div> */}
                    
                    <NavLink  to='/services'><li  onClick={useScrollToTop}>Services</li></NavLink>
                    
                    <NavLink to='/gallery'><li  onClick={useScrollToTop}>Gallery</li></NavLink>

                    <NavLink  to='/about'><li  onClick={useScrollToTop}>About</li></NavLink>
                    <NavLink  to='/contact'><li onClick={useScrollToTop}>Contact</li></NavLink>
                
            </ul>
            :  <>
                    <button onClick={burgerClickHandler} className={ burgerMenuConditionsAreMet() ? 'burger-btn burger-btn-active' : 'burger-btn'}>
                      <div>
                        <div className={ burgerMenuConditionsAreMet() ? 'burger-btn-line nav-active-burger-line1' : 'burger-btn-line'}></div>
                        <div  className={ burgerMenuConditionsAreMet() ? 'burger-btn-line nav-active-burger-line2' : 'burger-btn-line'}></div>
                        <div className={ burgerMenuConditionsAreMet() ? 'burger-btn-line nav-active-burger-line3' : 'burger-btn-line'}></div>
                      </div>
                    </button> 
                    <ul ref={burgerNavBtn} className="active-nav-list">
                        <NavLink  to='/'><li ref={burgerNavBtn} onClick={useScrollToTop} className="active-nav-home-btn">Home</li></NavLink>
                        <div></div>
                        <NavLink  to='/services'><li  onClick={useScrollToTop}>Services</li></NavLink>
                        <NavLink  to='/gallery'><li  onClick={useScrollToTop}>Gallery</li></NavLink>
                        <NavLink  to='/about'><li  onClick={useScrollToTop}>About</li></NavLink>
                        <NavLink  to='/contact'><li onClick={useScrollToTop}>Contact</li></NavLink>
                        </ul>
                    </>
                }
    </div>
  </nav>
  );
}

export default Nav;
