import React from 'react';
//css from services.css
import backgroundImg1 from '../assets/sec-header-bg2.jpg'
import backgroundImg2 from '../assets/sec-header-bg.jpg'

import backgroundImg3 from '../assets/sec-bg-3.jpg'
import backgroundImg4 from '../assets/sec-bg-4.jpg'
import backgroundImg5 from '../assets/sec-bg-5.jpg'
import './SecondaryHeader.css'



// component expects a 'text' property - giving it a the text in the black box
// also expects a background property - which can either be a color (given by a css string - e.g. "(rgba('255, 0, 0, 1')
// or an image url (witht he css prop synthax again... - 'url(...chosen image...)')
function SecondaryHeader(props) {

    const [imageCounter, setImageCounter] = React.useState(1)
    
    
    const [translateXValue, setTranslateXValue] = React.useState(0)
    const [idkwtf, setidkwtf] = React.useState(`translateX(${translateXValue}%)`)
    const [counter, setCounter] = React.useState(1)
    let fullImageArray = [<img key='first' src={backgroundImg1} />, <img key='second' src={backgroundImg2} />, <img key='third' src={backgroundImg3} />, <img key='forth' src={backgroundImg4} />, <img key='firstCloned' src={backgroundImg1} />, ]

    

    let headerImageRef = React.useRef()
    let headerImageRef2 = React.useRef()
    
    
    React.useEffect(()=>{
        
        headerImageRef.current.style.transform = idkwtf
        if(counter == 1){
            let interval = setInterval(()=>{

                if (counter == 1){
                    headerImageRef.current.style.transition = 'none'
                }else{
                    headerImageRef.current.style.transition = 'transform 1s'
                }
                if(counter < fullImageArray.length ){
                    
                    setCounter(prevState => prevState + 1)
                    setTranslateXValue(prevState => prevState - 100)
                    setidkwtf(`translateX(${translateXValue}%)`)
                    headerImageRef.current.style.transform = idkwtf

                    // headerImageRef.current.style.transition = 'all 1s'
    
                }else {
                    // headerImageRef.current.style.transition = 'none'

                    setCounter(1)
                    setTranslateXValue(0)
                    setidkwtf(`translateX(${translateXValue}%)`)
                    headerImageRef.current.style.transform = idkwtf
                    
                }
    
            },1000)
    
            return () => clearInterval(interval)
        }else{
            let interval = setInterval(()=>{

                if (counter == 1){
                    headerImageRef.current.style.transition = 'none'
                }else{
                    headerImageRef.current.style.transition = 'transform 1s'
                }
                if(counter < fullImageArray.length ){
                    
                    setCounter(prevState => prevState + 1)
                    setTranslateXValue(prevState => prevState - 100)
                    setidkwtf(`translateX(${translateXValue}%)`)
                    headerImageRef.current.style.transform = idkwtf
                   
    
                }else {
                    // headerImageRef.current.style.transition = 'none'
                   
                    setCounter(1)
                    setTranslateXValue(0)
                    setidkwtf(`translateX(${translateXValue}%)`)
                    headerImageRef.current.style.transform = idkwtf
                    
                }
    
            },7000)
    
            return () => clearInterval(interval)
        }


       
        
    }, [imageCounter, translateXValue, idkwtf, counter])

    React.useEffect(()=>{
        function imageParallax() {
            
            headerImageRef.current.style.top = `-${window.scrollY * 0.08}%`
            
        }

        window.addEventListener('scroll', imageParallax)

        return () => {window.removeEventListener('scroll', imageParallax)}
    }, [])
  return (
        <>
            <div className="header-gap"></div>
            <header className="secondary-pages-header">
                <div className='fkr' ref={headerImageRef} >
                    {fullImageArray}
                </div>
                
                <div className='outer-text-wrapper'>
                    
                        {/* <h1 >Valentine’s Gardening</h1> */}
                        <h2 >Simply Relax & Let Me Do The Hard Work</h2>
                    
                </div>
                <div className='page-title-container' >
                    <div >
                        <h2 >{props.headerTitle}</h2>
                    </div>
                </div>
            </header>
        </>
  );
}

export default SecondaryHeader;









