import React from 'react';
    

// import { Backdrop } from '@react-three/drei';
import SecondaryHeader from '../../components/SecondaryHeader';
import emailjs from '@emailjs/browser';
import './Contact.css'
import SectionTitle from '../../components/SectionTitle';




function Contact() {
    
  
  

  // const [scrollPosition, setScrollPosition] = React.useState(0);
  // const handleScroll = () => {
      
  //     setScrollPosition(window.pageYOffset);
  // };
  
  const [placeClientFoundUsAt, setPlaceClientFoundUsAt] = React.useState('Select an Option')

  function onChangeHandler(e) {
    setPlaceClientFoundUsAt(e.target.value)
  }

  // React.useEffect(() => {
  //     window.addEventListener('scroll', handleScroll, { passive: true });

  //     return () => {
  //         window.removeEventListener('scroll', handleScroll);
  //     };
  // }, []);

  const form = React.useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_0ed9jmo', 'template_tukhkk9', form.current, 'avmWq-jChB0MbgwQm')
      .then((result) => {
          console.log(result.text);
          alert('Form submitted. We will be in touch with you as soon as possible!')
          e.target.reset()
      }, (error) => {
          console.log(error.text);
      });
  };
  let ss = React.useRef(false)
  return (
    <div className="Contact" >
      <SecondaryHeader headerTitle='CONTACT' />
      <section className='contact-section'>
      <SectionTitle title='Get In Touch With Us Today' />
      <div className='contacts-main-grid-container3'>

        <form ref={form} onSubmit={sendEmail}>
          <div className='form-inner-width' >
          <h2 >Contact Us</h2>
          <hr></hr>
          <p>Submit your inquiry in the form below and we will contact you right back as soon as possible.</p>
          <p>Alternatively, feel free to reach out to us via our contact details.</p>
          </div>
          
          <div className='form-inner-width2' >
          <div className='form-inner-grid-container'>
              {/* <div style={{display: 'grid'}}> */}
            <label >Name: *</label>
            <input type="text" name="user_name" required/>
            {/* </div> */}
            {/* <div style={{display: 'grid'}}> */}
            <label>E-mail: *</label>
            <input type="email" name="user_email" required/>
            {/* </div> */}
          </div>
        <div className='form-inner-grid-container'>
        <label>Message: *</label>
        <textarea name="message"/>
        </div>
        <label >How did you hear about us? *</label>
          <select name="how_did_they_hear_about_you" required onChange={onChangeHandler} defaultValue={'Select an option'}>
            <option value="Select an option" disabled >Select an option</option>
            <option value='Friends & Family'>Friends & Family</option>
            <option value='Facebook'>Facebook</option>
            <option value='Instagram'>Instagram</option>
            <option value='Google Search'>Google Search</option>
            <option value='Leaflets'>Leaflets</option>
            <option value='Other'>Other</option>
            
          </select>
          {placeClientFoundUsAt == 'Other' && <><label >Please Specify</label>
            <input type="text" name="how_did_they_hear_about_you" required/></>}
        <input type="submit" value="Send" className='form-submit-button' />
        </div>
        </form>

        <div className='contact-info-grid-container2'>
          <div>
          <h4>Contact Details</h4>
          <p>07460 717222</p>
          <p>vboradzhiev@gmail.com</p>
          </div>
          <div>
          <h4>Working Hours</h4>
          <p>8AM - 5PM, Mon to Sat</p>
          
          </div>
          <div>
          <h4>Social Links</h4>
          <a target='_blank'href='https://www.facebook.com/valentinesgardening'>Facebook</a>
          <br />
          <a target='_blank'href='https://instagram.com/valentin.gardening?igshid=ZDdkNTZiNTM='>Instagram</a>
          </div>
        </div>
      </div>
        

      </section>
    </div>
  );
}

export default Contact;
