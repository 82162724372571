import React from 'react';

import garden2 from '../../assets/daisies-2365910_1920.jpg'
import AboutUsSection from './AboutUsSection';
import './AboutUs.css'
// import { Backdrop } from '@react-three/drei';
import SecondaryHeader from '../../components/SecondaryHeader';
// import SectionSplitter from '../../components/SectionSplitter';



function AboutUs() {
   
  
  

  const [scrollPosition, setScrollPosition] = React.useState(0);
  const handleScroll = () => {
      
      setScrollPosition(window.pageYOffset);
  };

  React.useEffect(() => {
      window.addEventListener('scroll', handleScroll, { passive: true });

      return () => {
          window.removeEventListener('scroll', handleScroll);
      };
  }, []);

  return (
    <div className="AboutUs" >
      <SecondaryHeader headerTitle='ABOUT' />
        
        
      <AboutUsSection title='About Us' appear={scrollPosition >= 0 ? true : false} background={garden2} 
      list1='We are certified gardeners working within a licensed company. With more 10 years of experience, our team is always striving to be better. Led by our passion for gardening, we are dedicated to work on every project, as if it is our own garden.' 
      list2="Based and working in North London, we are ready to provide a professional gardening services, personalized to your specific needs. We have a great attention to detail and provide reliable services, whose sustainability is enhanced by our professional advice."
      list3="We own and use a variety of professional tools and equipment, enabling us to provide a massive range of services. Whether if you need a simple lawn mowing, or a full scale garden design and landscape renovation, we offer all at affordable prices, allowing you to take advantage of all-in-one packages, without the need of other contractors."
      />
      
      
      {/* <SectionSplitter text='Call me today: 07460 717 222' /> */}

      {/* <AboutUsSection  title='Location'appear={scrollPosition >= 0 ? true : false} background={garden2} text={'At Valentine’s you can find the right service, tailored to your garden needs. We aim to deliver quality services, offering a variety of gardening help and labor. Starting from basic garden consultations, trough garden installation and maintenance, to remodeling and restoring of your outdoor spaces, we provide a list of specialized services for every element in your garden'} />
      <h1 style={{fontSize: '3.75rem',margin: '0', textAlign: 'center', color: 'white', letterSpacing: '0.1rem', wordSpacing: '0.5rem 0',  fontFamily: "'Staatliches', cursive", backgroundColor: 'rgba(0,0,0,0.9)'}}>e-mail: vboradzhiev@gmail.com</h1>
      */}

    </div>
  );
}

export default AboutUs;
