import React from 'react';
import './services.css'
import ServicesPageSection from './components/ServicesPageSection';
import SecondaryHeader from '../../components/SecondaryHeader';
import '../Gallery/Gallery.css'
import SectionTitle from '../../components/SectionTitle';
import ServicesSection from './components/ServicesSection';

import PageContentWrapper from '../../components/PageContentWrapper';
import PageBackground from '../../components/PageBackground';

import background1 from '../../assets/444.jpg'
import uniqueImg from '../../assets/unique.jpg'
import sanctuaryImg from '../../assets/sanctuaryImg.jpg'

import planImg from '../../assets/planImg.jpg'
import weedingMaybeImg from '../../assets/weedingMaybeImg.jpg'
import treePruningImg from '../../assets/treePruning2.jpg'
import seasonalImg from '../../assets/seasonal1.jpg'


import pressureWash2 from '../../assets/pressureWash2.jpg'

import tiles from '../../assets/tiles.jpg'
import irrigationSystems from '../../assets/irrigationSystems.jpg'
import fencePainting from '../../assets/fencePainting.jpg'

import grasses1 from '../../assets/grasses1.jpg' //1


import hedgesAndFences from '../../assets/hedges-and-fences.jpg'
import plantingImage from '../../assets/planting.jpg'
import patio from '../../assets/patio.jpg'

import tree from '../../assets/tree.jpg'


function Services() {
    const [scrollPosition, setScrollPosition] = React.useState(0);
    
    
    

    const [servicesToMount, setServicesToMount] = React.useState('gardeningServices')
    const [windowWidth, setWindowWidth] = React.useState(window.innerWidth)

    
    const handleResize = () => {
        setWindowWidth(window.innerWidth)
    }

    React.useEffect(()=>{
        window.addEventListener('resize', handleResize)
        
        return ()=>{
            window.removeEventListener('resize', handleResize)
        }
    },[])

    const handleScroll = () => {
        setScrollPosition(window.pageYOffset);
        
};
    // let grass3 = 'https://cdn.pixabay.com/photo/2016/06/27/17/54/leaf-1482948_960_720.jpg' 25%opacity filter

    
    let idkRef = React.useRef([])
    
    React.useEffect(()=>{
        let servicesSectionNodes = document.getElementsByClassName('ServicesPageSection')

    for(let i = 0; i < servicesSectionNodes.length; i++){
        idkRef.current[i] = servicesSectionNodes[i].offsetTop - window.innerHeight + 120
        
    }
    },[windowWidth])
React.useEffect(() => {
    
    // idkRef.current = servicesSectionNodes[1].offsetTop
    // servicesSectionNodes.forEach(element => {
    //     idkRef.current = [...idkRef.current, element.offsetTop]
    // });
    
    // servicesSectionNodes.forEach(element => {
    //     setServicesSectionsPositions([...servicesSectionsPositions, element.offsetTop])
    // });
    // setServicesSectionsPositions([...servicesSectionsPositions, servicesSectionNodes[0].offsetTop])
    
    window.addEventListener('scroll', handleScroll, { passive: true });


    return () => {
        window.removeEventListener('scroll', handleScroll);
    };
}, [scrollPosition]);
  
   

  return (
    <div className="Services" >
        <PageBackground image={background1}/>
        {/* <div style={{background: `url(${grass3})`, backgroundSize: 'cover', position: 'absolute', top: '0', bottom:'0', left: '0', right: '0', zIndex: '-1', filter: 'blur(1px) opacity(25%)'  }}></div> */}
        {/* <Nav /> */}
        <SecondaryHeader headerTitle='SERVICES' />
        <PageContentWrapper >
            <div className='gallery-intro' style={{gridTemplateColumns: '1fr 1fr 1fr'}}>
                <h2 className="sectionButton"  onClick={()=>{setServicesToMount('gardeningServices')}}>Gardening Services</h2>        
                <h2 className="sectionButton" onClick={()=>{setServicesToMount('gardenDesigns')}} style={{borderLeft: '2px solid white', borderRight: '2px solid white'}}>Garden Designs</h2>
                <h2 className="sectionButton"  onClick={()=>{setServicesToMount('landscapingServices')}}>Landscaping Services</h2>
            </div>
                {servicesToMount == 'gardeningServices' ? 
                <>
                    <SectionTitle title='Garden Services' />
                    <div className='individual-services-container'>
                        <ServicesPageSection 
                            image={grasses1}
                            service='Grass'
                            appear={scrollPosition >= 0 ? true : false}
                            description='Our team of experienced landscapers is dedicated to providing reliable, efficient, and affordable grass cutting, trimming, as well as renovation and practical advice for seeds and maintenance.'
                        />
                        <ServicesPageSection 
                            image={hedgesAndFences} 
                            service='Hedges & Fences' 
                            appear={scrollPosition >= idkRef.current[1] ? true : false} 
                            flip={true}
                            description=' We specialize in providing reliable solutions for all your fencing and hedging needs. Whether you need a new fence installed, an existing fence repaired or a hedge trimmed, we will provide you with top-quality hedge and fence services.'
                        />
                        <ServicesPageSection 
                            image={plantingImage} 
                            service='Planting, Weeding & Spraying' 
                            appear={scrollPosition >= idkRef.current[2] ? true : false}
                            description='With our extensive knowledge and unrivaled attention to detail, we are dedicated to help you with all your gardening needs, from pruning and mulching to more extensive weed control, planting, and spraying.'
                        />
                        <ServicesPageSection 
                            image={patio} 
                            service='Patio' 
                            appear={scrollPosition >= idkRef.current[3] ? true : false} 
                            flip={true}
                            description='Our team of experts is dedicated to providing high-standard services in order to your unique needs. Whether you need a new patio installation, cleaning, repairs or any other form of maintenance services, we have got you covered.'
                        />
                        <ServicesPageSection 
                            image={tree} 
                            service='Trees' 
                            appear={scrollPosition >= idkRef.current[4] ? true : false}
                            description="Trees require care and maintenance to thrive and remain healthy. This is where we come in, to provide a range of services, including tree trimming, pruning, removal, and stump grinding, among others."
                        />
                        <ServicesPageSection
                            image={pressureWash2} 
                            service='Jet Washing' 
                            appear={scrollPosition >= idkRef.current[5] ? true : false}
                            description='Jet washing is a powerful cleaning method that can restore the look and feel of your garden or outdoor space. Using high-pressure water jets, we can remove dirt, grime, and stains from your patio, driveway, and decking.'
                        />
                        <ServicesPageSection 
                            image={tiles} 
                            service='Tiles' 
                            appear={scrollPosition >= idkRef.current[6] ? true : false}
                            description='We have years of experience in installing and maintaining outdoor tiles, ensuring that they remain in excellent condition throughout the year.'
                        />

                        <ServicesPageSection 
                            image={irrigationSystems} 
                            service='Irrigation Systems' 
                            appear={scrollPosition >= idkRef.current[7] ? true : false}
                            description='We can design, install, and maintain irrigation systems tailored to your specific needs, whether in a small garden or a large commercial property. Automated systems let you save time and water, securing the health of your plants all year round.'
                        />

                        <ServicesPageSection 
                            image={fencePainting} 
                            service='Fence Painting' 
                            appear={scrollPosition >= idkRef.current[8] ? true : false}
                            description='Fence painting is a simple and cost-effective way to enhance the appearance of your garden or outdoor space. We use high-quality paints and stains that are both durable and long-lasting, ensuring that your fence remains beautiful for years to come.'
                        />

                    </div>
                </>
                : servicesToMount == 'gardenDesigns' ? <>
                                
                                <SectionTitle title='Garden Designs' />
                            <div className='whole-services-container' >
                            {/* <div className='services3' style={{position: 'absolute', top: '0', width: '100%'}}>
                                </div>
                                <div className='services4' style={{position: 'absolute', top: '0', width: '100%'}}>
                                </div>
                                <div className='services5' style={{position: 'absolute', top: '0', width: '100%'}}>
                                </div> */}
                                <ServicesSection 
                                    image={sanctuaryImg} 
                                    description="We believe that a beautiful outdoor space can transform your home and provide a sanctuary for you and your family. This is why we'd like to help turn your outdoor space into a stunning oasis
        that you can enjoy all year round."
                                />
                                

                                <ServicesSection image={uniqueImg} description='We understand that every garden is unique, which is why we offer customized services tailored to your specific 
    needs. Our team of experienced garden designers will work with you to create a bespoke garden that suits your lifestyle and preferences. '/>
                                
                                <ServicesSection image={planImg} description='Get advice on types of materials and their use, ideas for combining the old with new, and recommendations of stores and warehouses to provide you with high-quality elements for the perfect garden design.There are numerous shapes, colours, layouts and styles you can choose from, get your garden design advice today!'/>

                </div>
                </>
                : <>
                        <SectionTitle title='Landscaping Services' />
                        <div className='whole-services-container' >
                            
                                <ServicesSection image={weedingMaybeImg} description=" We will check and determine the soil type, help with soil enhancement and recommend types of seeds ideal for your surroundings. If you like different textures and something more than just green grass, we provide mulch instalment and replacement, as well as hand weeding to all your surfaces and planters."/>
                                

                                <ServicesSection image={treePruningImg} description="Trees require care and maintenance to thrive and remain healthy. At Valentine's Gardening, we provide a range of services, including deep root and strump removals, tree trimming, branch redirection, tree replacements, pruning of hedges and shrubs, as well as shaping and rearranging."/>
                                
                                <ServicesSection image={seasonalImg} description='Call Valentine’s for seasonal services, like snow removal, leaf removal and gutter cleaning. Ask for advice on how to protect your garden during the seasons by booking an appointment for a landscaping maintenance checkup, in order to protect your garden from expensive damages and timely repairs.'/>

                                
                </div>
                </>}
        </PageContentWrapper>
        

    
        
    
    
    </div>
  );
}

export default Services;
