import './SectionSplitter.css'


// component expects a 'text' property - giving it a the text in the black box
// also expects a background property - which can either be a color (given by a css string - e.g. "(rgba('255, 0, 0, 1')
// or an image url (witht he css prop synthax again... - 'url(...chosen image...)')
function SectionSplitter(props) {

    return (
        <div className='SectionSplitter'>
            <h2 >{props.text}</h2>
        </div>
    );
  }
  
  export default SectionSplitter;
  
  
  
  
  
  
  
  
  





<div style={{fontSize: 'clamp(0.8rem, 2.8vh, 10rem)',backgroundColor: 'rgba(0,0,0,0.9)', display: 'grid', alignItems: 'center', width: '100%', height: '10vh'}}>
      <h2 style={{ margin: '0', textAlign: 'center', color: 'white', letterSpacing: '0.1rem', wordSpacing: '0.5rem 0',  fontFamily: "'Staatliches', cursive"}}>Simply relax and let me do the hard work</h2>
      </div>