import React from 'react';
import '../Gallery.css'


function GeneralLibraryImage(props) {
    const [openStatus, setOpenStatus] = React.useState(false)
    const [theFuckingScrollY, setTheFuckingScrollY] = React.useState(0)

    function clickHandler(){
        setOpenStatus(prevState => !prevState)
    }

    function disableScroll(e){
        setTheFuckingScrollY(window.scrollY)
        let bodyNode = document.getElementsByTagName('body')
        
        bodyNode[0].style.overflow = 'hidden'
        bodyNode[0].style.position = 'fixed'
        
        
    }

    function enableScroll(e){
        
        let bodyNode = document.getElementsByTagName('body')
        bodyNode[0].style.overflow = 'unset'
        bodyNode[0].style.position = 'unset'
        window.scrollBy(0,theFuckingScrollY)
        console.log(theFuckingScrollY)
    }
    
    const unopenedImageJSX =(
        <div  onClick={(e) =>{clickHandler(); disableScroll(e)}} className="GeneralLibraryImage" >
                                <div className='general-gallery-image-wrapper'>
                                    {/* <div className='on-hover-info'>
                                        <h3 style={{color: 'white', margin: '0'}}>{props.title}</h3>
                                        <h4 style={{color: 'white', margin: '0'}}>{props.subInfo}</h4>
                                    </div> */}
                                    <img loading='lazy' src={props.image}/>
                                </div>
                                </div>
                            )
    
    const openedImageJSX =  (
                            <div  onClick={(e) =>{clickHandler(); enableScroll(e)}} className="GeneralLibraryImage" >
                                <div className='gallery-image-opened' >
                                    <h4 className='opened-img-msg'>click anywhere to go back</h4>
                                    <img src={props.image}/>
                                </div>
                            </div>
                            )
  return (
        <>
            {
                !openStatus ? unopenedImageJSX : openedImageJSX 
            }   
        </>
  );
}

export default GeneralLibraryImage;
