import './App.css';
import Home from './pages/Home';
import Services from './pages/Services/Services';
import AboutUs from './pages/AboutUs/AboutUs';
import { Route, Routes } from 'react-router-dom';
import Gallery from './pages/Gallery/Gallery';
import Nav from './components/Nav';
import './components/Header.css'
import Contact from './pages/Contact/Contact';
import Footer from './components/Footer';
// import React from 'react';
import { useState, useEffect } from 'react';

function App() {

    // const [checkHome, setCheckHome] = React.useState()
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth)



    useEffect(()=>{

      const handleResize = () => {
        setViewportWidth(window.innerWidth)
  
    }

          window.addEventListener('resize', handleResize)
        return () => {
          window.removeEventListener('resize', handleResize)
        }
      },[viewportWidth])


  return (
    <div className="App" >
      <Nav viewportWidth={viewportWidth}/>
      <Routes>
    <Route path='/' element={<Home viewportWidth={viewportWidth}/>}/>
    <Route path='/services' element={<Services/>}/>
    <Route path='/about' element={<AboutUs/>}/>
    <Route path='/gallery' element={<Gallery/>}/>
    <Route path='/contact' element={<Contact/>}/>
    </Routes>
    <Footer />
    </div>
  );
}

export default App;
