import '../services.css'
import './ServicesSection.css'
// component expects a 'text' property - giving it a the text in the black box
// also expects a background property - which can either be a color (given by a css string - e.g. "(rgba('255, 0, 0, 1')
// or an image url (witht he css prop synthax again... - 'url(...chosen image...)')
function ServicesSection(props) {

  return (
        <>
        <img className='services-section-image' src={props.image}></img>
                            <div className='services2-text-wrapper'>
                            <div className='services2 services2-hidden-placeholder'>
                                <p>
                                {props.description}
                                </p>
                            </div>
                            <div className='services2 absolute-content-to-appear'>
                                <p >
                                {props.description}
                                </p>
                            </div>
                            </div>
        </>
  );
}

export default ServicesSection;






