import React from 'react'
import '../../../components/ss.css'


// component expects a 'text' property - giving it a the text in the black box
// also expects a background property - which can either be a color (given by a css string - e.g. "(rgba('255, 0, 0, 1')
// or an image url (witht he css prop synthax again... - 'url(...chosen image...)')

//boxShadow: ' inset 0rem -1rem  1rem rgba(0,0,0,0.8), inset 0rem 1rem 1rem rgba(0,0,0,0.8)'
// width: `${props.appear ? '95%' : '45%'}`,
function ServicesPageSection(props) {

    

    // function handleMouseEnter(){
    //     setHover(true)
    // }
    
    // function handleMouseOut() {
    //     setHover(false)
    // }

    // function handleClick(){
    //     setOpen(!open)
    // }

    // React.useEffect(()=>{
    //     if(hover){
    //         idkRef.current.style.backgroundColor = 'red'
    //     }else{
    //         idkRef.current.style.backgroundColor = 'unset'
    //     }
    // }, [hover])

    let idkRef = React.useRef()
  return (
    // margin: `${!props.flip ? '0rem auto auto 0rem' : '0rem 0rem auto auto'}`
    // makes them come from different sides, 
    // margin: `0` makes them come from the same side
    <section ref={idkRef} className="ServicesPageSection" style={{ width: `${(props.appear) && (window.innerHeight > 730) ? '100%' : !props.appear && (window.innerWidth > 730) ? '34%' : '100%'}` }}>
        
            
                <div className='services-container'>
                    <div className="grid-wrapper" >
                        {/* {!props. flip ? <>
                            <div className="services-grid-item2"><img style={{width: '20rem'}} src={flower1}></img></div> 
                            <div>
                            <div className="services-grid-item1"><p style={{color: 'white', backgroundColor: 'rgba(0,0,0,0.8)', padding: '5rem'}}>We offer these nuts and haha lol wtf is going in this is jebait dab</p></div>
                            </div>
                        </>
                        :
                        <>
                            <div>
                            <div className="services-grid-item1"><p style={{color: 'white', backgroundColor: 'rgba(0,0,0,0.8)', padding: '5rem'}}>We offer these nuts and haha lol wtf is going in this is jebait dab</p></div>
                            </div>
                            <div className="services-grid-item2"><img style={{width: '20rem'}} src={flower1}></img></div> 
                        </>} */}

                        <div className="services-grid-item2" >
                            <img  src={props.image}></img>
                            <h2 >{props.service}</h2>
                            
                            </div> 

                            
                            <div className="services-grid-item1" >
                            {/* <div style={{height: '100%', width: '1px', background: 'white'}}></div> */}
                                {window.innerWidth < 730 && <div className='individual-service-slide-down-cover' style={{background: ' rgba(255, 255, 255, 1)', bottom: `${props.appear? '-100%' : '0'}` }}></div>}
                                <p className='services-text'>{props.description}</p>
                            </div>
                            
                        
                    </div>
                </div>
                
            
        
        {/* <div style={{height: '1px', width: '100%', backgroundColor: 'black'}}></div> */}
        
    </section>
  );
}

export default ServicesPageSection;




















/* V2 */

// import React from 'react';
// import blackWave from '../assets/black_wave.png'

// import leaves1 from '../assets/leaves.png'
// import './ss.css'
// import grass1 from '../assets/meadow1.jpg'
// import grass2 from '../assets/meadow2.jpg'
// import flower1 from '../assets/flowerServices.jpg'

// // component expects a 'text' property - giving it a the text in the black box
// // also expects a background property - which can either be a color (given by a css string - e.g. "(rgba('255, 0, 0, 1')
// // or an image url (witht he css prop synthax again... - 'url(...chosen image...)')

// //boxShadow: ' inset 0rem -1rem  1rem rgba(0,0,0,0.8), inset 0rem 1rem 1rem rgba(0,0,0,0.8)'
// // width: `${props.appear ? '95%' : '45%'}`,
// function ServicesPageSection(props) {

//     const [hover, setHover] = React.useState(false)
//     const [open, setOpen] = React.useState(false)

//     // function handleMouseEnter(){
//     //     setHover(true)
//     // }
    
//     // function handleMouseOut() {
//     //     setHover(false)
//     // }

//     // function handleClick(){
//     //     setOpen(!open)
//     // }

//     // React.useEffect(()=>{
//     //     if(hover){
//     //         idkRef.current.style.backgroundColor = 'red'
//     //     }else{
//     //         idkRef.current.style.backgroundColor = 'unset'
//     //     }
//     // }, [hover])

//     let idkRef = React.useRef()
//   return (
//     // <section ref={idkRef} className="ServicesPageSection" style={{borderRadius: `${props.flip? '1rem 0.2rem 0.2rem 1rem' : '0.2rem 1rem 1rem 0.2rem'}`, backgroundColor: `${hover || props.appear? 'rgba(0,0,0, 0.75)' : 'unset'}`, width: `${hover || props.appear || open ? '95%' : '45%'}`, margin: `${!props.flip ? '0rem auto auto 1.5rem' : '0rem 1.5rem auto auto'}`, transition: 'all 1s' }}>
    
//     <section ref={idkRef} className="ServicesPageSection" style={{borderRadius: `0`, backgroundColor: `${hover || props.appear? 'rgba(0,0,0, 0.75)' : 'unset'}`, width: `${hover || props.appear || open ? '100%' : '45%'}`, margin: `0`, transition: 'all 1s' }}>
        
//         {/* <div className='services-grid-container'
//                     style={{height: '20rem', width: '100%',  overflow: 'hidden', borderRadius: `${props.flip? '1rem 0.2rem 0.2rem 1rem' : '0.2rem 1rem 1rem 0.2rem'}` }}
//                     > */}
//                 <div className='services-grid-container'
//                     style={{height: '20rem', width: '100%',  overflow: 'hidden', borderRadius: `0` }}
//                     >
//                     <div className="grid-wrapper" style={{width: '100vw',background: 'rgba(0,0,0,0.6)',display: 'grid', justifyContent: 'start', justifyItems: 'left', alignItems: 'center', gridTemplateColumns: '35% 40%', height: '100%',  overflow: 'hidden'}}>
//                         {/* {!props. flip ? <>
//                             <div className="services-grid-item2"><img style={{width: '20rem'}} src={flower1}></img></div> 
//                             <div>
//                             <div className="services-grid-item1"><p style={{color: 'white', backgroundColor: 'rgba(0,0,0,0.8)', padding: '5rem'}}>We offer these nuts and haha lol wtf is going in this is jebait dab</p></div>
//                             </div>
//                         </>
//                         :
//                         <>
//                             <div>
//                             <div className="services-grid-item1"><p style={{color: 'white', backgroundColor: 'rgba(0,0,0,0.8)', padding: '5rem'}}>We offer these nuts and haha lol wtf is going in this is jebait dab</p></div>
//                             </div>
//                             <div className="services-grid-item2"><img style={{width: '20rem'}} src={flower1}></img></div> 
//                         </>} */}
//                         <div className="services-grid-item2" style={{ justifySelf: 'center'}}><img style={{width: '20rem', height: '100%', borderRadius: '1rem', border: '3px solid white', boxShadow: 'inset 0rem -3rem  white, inset 0rem 3rem  white'}} src={flower1}></img><h2 style={{textAlign: 'center', color: 'white'}}>{props.service}</h2></div> 
//                             <div>
//                             <div className="services-grid-item1" style={{width: '80%'}}><p style={{color: 'white', backgroundColor: 'rgba(0,0,0,0.8)', padding: '2rem'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p></div>
//                             </div>
                        
//                     </div>
//                 </div>
                
            
        
//         {/* <div style={{height: '5px', width: '100%', backgroundColor: 'rgba(0,0,0,0.8)'}}></div> */}
        
//     </section>
//   );
// }

// export default ServicesPageSection;















// V1

// import React from 'react';
// import blackWave from '../assets/black_wave.png'

// import leaves1 from '../assets/leaves.png'
// import './ss.css'
// import grass1 from '../assets/meadow1.jpg'
// import grass2 from '../assets/meadow2.jpg'
// import flower1 from '../assets/flowerServices.jpg'

// // component expects a 'text' property - giving it a the text in the black box
// // also expects a background property - which can either be a color (given by a css string - e.g. "(rgba('255, 0, 0, 1')
// // or an image url (witht he css prop synthax again... - 'url(...chosen image...)')

// //boxShadow: ' inset 0rem -1rem  1rem rgba(0,0,0,0.8), inset 0rem 1rem 1rem rgba(0,0,0,0.8)'
// // width: `${props.appear ? '95%' : '45%'}`,
// function ServicesPageSection(props) {

//     const [hover, setHover] = React.useState(false)
//     const [open, setOpen] = React.useState(false)

//     // function handleMouseEnter(){
//     //     setHover(true)
//     // }
    
//     // function handleMouseOut() {
//     //     setHover(false)
//     // }

//     // function handleClick(){
//     //     setOpen(!open)
//     // }

//     // React.useEffect(()=>{
//     //     if(hover){
//     //         idkRef.current.style.backgroundColor = 'red'
//     //     }else{
//     //         idkRef.current.style.backgroundColor = 'unset'
//     //     }
//     // }, [hover])

//     let idkRef = React.useRef()
//   return (
//     <section ref={idkRef} className="ServicesPageSection" style={{borderRadius: `${props.flip? '1rem 0.2rem 0.2rem 1rem' : '0.2rem 1rem 1rem 0.2rem'}`, backgroundColor: `${hover || props.appear? 'rgba(0,0,0, 0.75)' : 'unset'}`, width: `${hover || props.appear || open ? '95%' : '45%'}`, margin: `${!props.flip ? '5rem auto auto 0rem' : '5rem 0rem auto auto'}`, transition: 'all 1s' }}>
        
            
//                 <div className='services-grid-container'
//                     style={{height: '20rem', width: '100%',  overflow: 'hidden', borderRadius: `${props.flip? '1rem 0.2rem 0.2rem 1rem' : '0.2rem 1rem 1rem 0.2rem'}` }}
//                     >
//                     <div className="grid-wrapper" style={{width: '100vw',background: 'rgba(0,0,0,0.6)',display: 'grid', justifyItems: 'start', alignItems: 'center', gridTemplateColumns: '45% 55%', height: '100%', width: '100vw',  overflow: 'hidden'}}>
//                         {/* {!props. flip ? <>
//                             <div className="services-grid-item2"><img style={{width: '20rem'}} src={flower1}></img></div> 
//                             <div>
//                             <div className="services-grid-item1"><p style={{color: 'white', backgroundColor: 'rgba(0,0,0,0.8)', padding: '5rem'}}>We offer these nuts and haha lol wtf is going in this is jebait dab</p></div>
//                             </div>
//                         </>
//                         :
//                         <>
//                             <div>
//                             <div className="services-grid-item1"><p style={{color: 'white', backgroundColor: 'rgba(0,0,0,0.8)', padding: '5rem'}}>We offer these nuts and haha lol wtf is going in this is jebait dab</p></div>
//                             </div>
//                             <div className="services-grid-item2"><img style={{width: '20rem'}} src={flower1}></img></div> 
//                         </>} */}
//                         <div className="services-grid-item2" style={{ justifySelf: 'center'}}><img style={{width: '20rem', height: '100%', borderRadius: '1rem', border: '3px solid white', boxShadow: 'inset 0rem -3rem  white, inset 0rem 3rem  white'}} src={flower1}></img><h2 style={{textAlign: 'center', color: 'white'}}>{props.service}</h2></div> 
//                             <div>
//                             <div className="services-grid-item1" style={{width: '80%'}}><p style={{color: 'white', backgroundColor: 'rgba(0,0,0,0.8)', padding: '2rem'}}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.</p></div>
//                             </div>
                        
//                     </div>
//                 </div>
                
            
        
//         {/* <div style={{height: '5px', width: '100%', backgroundColor: 'rgba(0,0,0,0.8)'}}></div> */}
        
//     </section>
//   );
// }

// export default ServicesPageSection;