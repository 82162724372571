import React from "react";

import './Reviews.css'

function Reviews() {
    let picNum = React.useRef(0)
    const [opacity, setOpacity] = React.useState(1)
   
    const [changeSwitch, setChangeSwitch] = React.useState(false)
    const [changeDirection, setChangeDirection] = React.useState(2)
    let imgRef = React.useRef()

    let reviewsArray = [
        
        {review: <div ref={imgRef}><p>Very happy with Valentine's gardening services. They worked really hard and charged a fair and decent price. Thank you!</p><p>Sydney Reece</p></div>},
        {review: <div ref={imgRef}><p>Fantastic work and very professional - gets things done on time and to budget. They can take on any project so be imaginative and they will meet your demans! Thanks</p><p>Shivum Kakkad</p></div>},
        
        {review: <div ref={imgRef}><p>Great service very prompt and professional done what we agreed without any problems would recommend to others and will use again.</p><p>John Christodoulou</p></div>},
        
        {review: <div ref={imgRef}><p>The landscaping work was done to a high standard and to the timetable. The team worked very hard.</p><p>Petra Griffiths</p></div>},
        {review: <div ref={imgRef}><p>Fantastic professional service, very quick and respectful to my property and my neighbours. Would highly recommend. Thank you!</p><p>Samantha Keeble</p></div>},

    ]

    
    
    function clickHandler() {
        setChangeDirection(1)
        setChangeSwitch(true)
        
    }

    function clickHandler2() {
        setChangeDirection(0)
        setChangeSwitch(true)
    }
    
    React.useEffect(() => {

        function fade(){
            
            if(imgRef.current.style.opacity > 0.1){
                setOpacity(prevState => (prevState - 0.1).toFixed(2))

            imgRef.current.style.opacity = opacity 
            }else{
                setChangeSwitch(false)
            }
        }

        function appear(){
                setOpacity(prevState => (prevState - -0.1).toFixed(2))

            imgRef.current.style.opacity = opacity
        }

        if(changeSwitch){
        const interval = setInterval(() => {
            fade()
          }, 10)
        
          return () => clearInterval(interval)
        }else if(!changeSwitch && imgRef.current.style.opacity < 1){
            if(changeDirection === 1 && imgRef.current.style.opacity == 0){
                
                    if(picNum.current < reviewsArray.length - 1){
                        picNum.current = picNum.current + 1
                        setChangeDirection(3)
                       
                    }else{
                        picNum.current = 0
                        setChangeDirection(3)
                    }
                
            }else if(changeDirection === 0 && imgRef.current.style.opacity == 0){
                if(picNum.current > 0){
                    picNum.current = picNum.current - 1
                    setChangeDirection(3)
                }else{
                    picNum.current = reviewsArray.length - 1
                    setChangeDirection(3)
                }
            }
            const interval2 = setInterval(() => {
                appear()
              }, 10)
            
              return () => clearInterval(interval2)
        }
        

    },[picNum,opacity,changeSwitch, changeDirection, reviewsArray.length])

    

    return (
      <section className="Reviews">
        <div className="review-change-container">
            <button onClick={clickHandler2}>⪻</button>
        <div className='review-container' >
            
            {reviewsArray[picNum.current].review}
            
        </div>
        
        <button onClick={clickHandler}>⪼</button>
        </div>
      </section>
    );
  }
  
  export default Reviews;