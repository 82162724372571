import React from 'react';
import '../Gallery.css'


function Card(props) {
    
    function clickHandler(e){
        
        let transformProp = e.currentTarget.firstChild.style.transform 
        let currentRotation = transformProp == 'rotateY(0deg)' || transformProp == '' ? 0 : Number(transformProp[8] + transformProp[9] + transformProp[10] + transformProp[11])
        if (currentRotation == 0 ) {
            e.currentTarget.firstChild.style.transform = `rotateY(${currentRotation - 180}deg)` 
        }else{
            e.currentTarget.firstChild.style.transform = `rotateY(${currentRotation + 180}deg)`
        }
        console.log(transformProp, currentRotation)
    }
    
    
    
  return (
    <div  onClick={clickHandler} className="Card" >

            <div  className='card-content'>

                <div  className='front-outer-wrapper'>
                <div  style={{background: `url("${props.frontImage}")`}} className='front'>
                    <h3 className='front-title' >Before</h3>
                    <div className='preview-cover'>
                        <h2 >CLICK TO SEE BEFORE AND AFTER</h2>
                    </div>
                </div>
                </div>

                <div className="back-outer-wrapper">
                    <div   style={{background: `url("${props.backImage}")`}} className='back'>
                        <h3 className='back-title'>After</h3>
                        
                    </div>
                </div>
                
            </div>
            <hr ></hr>
        </div>
  );
}

export default Card;
