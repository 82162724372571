import './Footer.css'
import facebookIcon from '../assets/facebook-icon.svg'
import instagramIcon from '../assets/instagram-icon.svg'
import barkLogo from '../assets/barkLogo.png'
import yellLogo from '../assets/yellLogo.png'

import { Link } from 'react-router-dom'
import useScrollToTop from '../hooks/scrollToTop'



function Footer() {
    //background: 'linear-gradient(rgba(29, 80, 14, 1)  1%, hsla(10, 79%, 26%, 1) ) '
  
  

  

  return (
    <footer className="Footer">
      
        <div className='footer-top-grid-container'>
        <div className='footer-sections-grid-container socials-grid'>
        <a target='_blank' href='https://www.facebook.com/valentinesgardening'><img className='footer-social-media-icon' src={facebookIcon}/></a>
        <a target='_blank' href='https://instagram.com/valentin.gardening?igshid=ZDdkNTZiNTM='><img className='footer-social-media-icon' src={instagramIcon}/></a>
        <a target='_blank' href='https://www.yell.com/biz/valentine-s-garden-services-london-8934613/'><img className='footer-social-media-icon' src={yellLogo}/></a>
        <a target='_blank' href='https://www.bark.com/en/gb/company/boradzhievi-ltd/QO1a/'><img className='footer-social-media-icon' src={barkLogo}/></a>
            </div>
            <ul className="footer-sections-grid-container">
        <Link to='/'><li  onClick={useScrollToTop}>Home</li></Link>
                    
                
            
                    
                    <Link  to='/services'><li onClick={useScrollToTop}>Services</li></Link>
                    
                    <Link  to='/gallery'><li onClick={useScrollToTop}>Gallery</li></Link>

                    <Link  to='/about'><li onClick={useScrollToTop}>About</li></Link>
                    <Link  to='/contact'><li onClick={useScrollToTop}>Contact</li></Link>
                
            </ul>
            
            
        </div>
        <div className='footer-copyright-text-box'>
            <p>Copyright © 2023 - <a>Valentine's Gardening</a>, All Rights Reserved</p>
        </div>
      
    </footer>
  );
}

export default Footer;
