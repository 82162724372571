// component expects a 'text' property - giving it a the text in the black box
// also expects a background property - which can either be a color (given by a css string - e.g. "(rgba('255, 0, 0, 1')
// or an image url (witht he css prop synthax again... - 'url(...chosen image...)')
function PageBackground(props) {

    return (
      <div className="PageBackground" style={{background: `url(${props.image})`, backgroundSize: `${props.bgSize ? props.bgSize : '100%'}`, backgroundRepeat: 'no-repeat', position: 'absolute', inset: '0', zIndex: '-1', filter: `blur(4px) opacity(${props.opacity ? props.opacity : '15%'})`}}>
        {props.children}
      </div>
    );
  }
  
  export default PageBackground;
  
  
  
  
  
  
  
  
  